<template>
  <el-container class="design-container">
    <el-main class="design-content">
      <div id="status" v-if="statusMsg">{{ statusMsg }}</div>
      <!-- <div class="content--left"></div> -->
      <div id="workspace" class="content--center">
        <div class="editor-canvas" v-if="designFaceData.length > 0">
          <div
            class="editor-canvas-bg"
            :style="{
              width: canvasW * zoomScale + 'px',
              height: canvasH * zoomScale + 'px',
            }"
          ></div>
          <fabric-editor
            v-for="(item, index) in designFaceData"
            v-show="index == faceIndex"
            :key="index"
            :index="index"
            :value="item"
            :width="canvasW"
            :height="canvasH"
            :left="designFaceShiftX"
            :top="designFaceShiftY"
            ref="editor"
            @zoomChange="handleZoomChange"
            @confirm="setCanvas(index)"
          ></fabric-editor>
        </div>
      </div>
      <div class="content--right" style="position: absolute; right: 0">
        <div class="design-effect">
          <div class="effect-preview">
            <el-carousel
              :height="600 + 'px'"
              arrow="always"
              :autoplay="false"
              trigger="click"
            >
              <el-carousel-item
                v-for="(item, index) in effectData"
                :key="index"
              >
                <effect
                  ref="effectRef"
                  :value="item"
                  :imgLayer="
                    currentSpecName && item.imgLayer?.[currentSpecName]
                      ? item.imgLayer?.[currentSpecName]
                      : 1
                  "
                  :modelUrl="
                    currentSpecName && item.model_url?.[currentSpecName]
                      ? item.model_url?.[currentSpecName]
                      : []
                  "
                  :id="index"
                  :type="2"
                  :images="
                    currentSpecName && item.images?.[currentSpecName]
                      ? item.images?.[currentSpecName]
                      : []
                  "
                  :modelParams="modelParams"
                ></effect>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <canvas
        ref="effectCanvas"
        id="effectCanvas"
        style="position: absolute; left: 100%; z-index: 1"
        width="1600"
        height="1600"
      ></canvas>
    </el-main>
  </el-container>
</template>
<script>
import FabricEditor from "./components/fabric-editor.vue";
import Effect from "./components/effect.vue";
import OSS from "@/utils/oss.js";
import md5 from "js-md5";
import { otherGetRequest, otherPostRequest, deepClone } from "@/utils/util.ts";
export default {
  components: {
    FabricEditor,
    Effect,
  },

  data() {
    return {
      statusMsg: "",
      id: 0,
      timer: null,
      second: 0,
      workspaceWidth: 0,
      workspaceHeight: 0,
      designFaceShiftX: 0,
      designFaceShiftY: 0,
      canvasW: 650,
      canvasH: 650,
      zoomScale: 1,
      canvasArr: [],
      fabricCanvas: null,
      editor: null,
      effectRef: null,
      productInfo: {},
      faceIndex: 0,
      faceName: "",
      currentFace: {},
      designFaceData: [],
      effectData: [{}],
      currentSpecName: "",
      modelParams: {},
      design_url: "",
      ossClass: null,
      target_type: 1,
      fontList: [],
      is_sku_list: 0,
      attrDesigns: [],
    };
  },
  watch: {
    faceIndex: {
      handler(val) {
        this.editor = this.$refs.editor[val];
        this.fabricCanvas = this.$refs.editor[val].canvas;
        this.currentFace = this.designFaceData[val];
        this.faceName = this.designFaceData[val].design_name;
      },
    },
  },
  methods: {
    setCanvas(index) {
      if (index == 0) {
        this.faceIndex = index;
      }
    },
    handleZoomChange(value) {
      const workspaceElement = document.getElementById("workspace");
      this.zoomScale = value;
      if (workspaceElement) {
        this.designFaceShiftX =
          (workspaceElement.offsetWidth - this.canvasW * value) / 2;
        this.designFaceShiftY =
          (workspaceElement.offsetHeight - this.canvasH * value) / 2;
      }
    },

    async refreshTexture(faceIndex = null, faceName = "") {
      return new Promise(async (resolve, reject) => {
        const canvas = !faceIndex
          ? this.fabricCanvas
          : this.$refs.editor[faceIndex].canvas;
        const designName = faceName || this.faceName;
        let defaultBgColor = null;
        let defaultTexture = null;
        canvas.overlayImage = null;
        // 使用默认底色
        if (!canvas.backgroundColor && this.currentFace.bg_color) {
          defaultBgColor = this.currentFace.bg_color;
        }
        if (defaultBgColor) {
          canvas.setBackgroundColor(
            defaultBgColor,
            canvas.renderAll.bind(canvas)
          );
        }
        // 使用默认贴图
        if (this.currentFace.texture) {
          defaultTexture = this.currentFace.texture;
          await this.setDefaultTexture(defaultTexture);
        }
        const scale = 1600;
        const texture = canvas.toDataURL({
          left: this.designFaceShiftX,
          top: this.designFaceShiftY,
          width: this.canvasW * this.zoomScale,
          height: this.canvasH * this.zoomScale,
          multiplier: scale / (this.canvasW * this.zoomScale),
          format: "png",
        });
        for (let index in this.effectData) {
          let effect = this.effectData[index];
          if (effect.is_diy) {
            let bigimg = await this.$refs.effectRef[index].setMapTexture({
              name: designName,
              url: texture,
              remove_color: defaultBgColor,
            });
            if (bigimg !== true) {
              this.$set(effect, "bigimg", bigimg);
            }
          }
        }
        resolve(true);
      });
    },
    setDefaultTexture(texture) {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(
          texture,
          (img) => {
            this.fabricCanvas.setBackgroundImage(
              img,
              this.fabricCanvas.renderAll.bind(this.fabricCanvas),
              {
                scaleX: this.canvasW / img.width,
                scaleY: this.canvasH / img.height,
              }
            );
            resolve(true);
          },
          { crossOrigin: "anonymous" }
        );
      });
    },
    //属性
    restoreAttr() {
      return new Promise((resolve, reject) => {
        if (this.attrDesigns && this.attrDesigns.length > 0) {
          for (let attr of this.attrDesigns) {
            let parts = deepClone(attr.value.parts);
            switch (attr.type) {
              case 1:
                for (let index in this.effectData) {
                  for (let part of parts) {
                    this.$refs.effectRef[index].setModelColor({
                      name: part,
                      color: attr.value.value.color,
                    });
                  }
                }
                break;
              case 2:
                for (let index in this.effectData) {
                  for (let part of parts) {
                    this.$refs.effectRef[index].setModelColor({
                      name: part,
                      color: attr.value.value.color,
                    });
                  }
                }
                break;
              case 3:
                for (let index in this.effectData) {
                  for (let part of parts) {
                    this.$refs.effectRef[index].setNormalMap({
                      name: part,
                      img: attr.value.value.image,
                      repeat: attr.value.value.normalmapRepeat,
                      scale: attr.value.value.normalmapScale,
                    });
                  }
                }
                break;
              case 4:
                break;
              case 5:
                for (let index in this.effectData) {
                  for (let part of parts) {
                    this.$refs.effectRef[index].setMapTexture({
                      name: part,
                      url: attr.value.value.image,
                    });
                  }
                }
                break;
              case 6:
                for (let index in this.effectData) {
                  this.$refs.effectRef[index].switchModel({
                    parts: parts,
                    model_url: attr.value.value.model_url[index],
                  });
                }
                break;
            }
          }
          resolve(true);
        } else {
          resolve(true);
        }
      });
    },
    async handleSave() {
      return new Promise(async (resolve, reject) => {
        let images = [];
        let sku_list = [];
        for (let index in this.effectData) {
          let effect = this.effectData[index];
          let imgs = effect.images[this.currentSpecName];
          if (effect.is_diy) {
            if (effect.bigimg) {
              imgs.splice(effect.currentImgIndex, 0, effect.bigimg);
            }
          }
          let img = await this.createEffectCanvas(imgs, effect.img_suffix);
          images.push(img);
          if ((this.target_type == 3 || this.is_sku_list == 1) && index == 0) {
            if (effect.is_diy) {
              if (effect.bigimg) {
                for (let spec of this.productInfo.spec_value_list) {
                  let spec_img = img;
                  if (spec.spec_value_str != this.currentSpecName) {
                    if (effect["images"][spec.spec_value_str]) {
                      let spec_imgs = effect["images"][spec.spec_value_str];
                      spec_imgs.splice(
                        effect.currentImgIndex,
                        0,
                        effect.bigimg
                      );
                      spec_img = await this.createEffectCanvas(
                        spec_imgs,
                        effect.img_suffix
                      );
                    }
                  }
                  sku_list.push({ id: spec.id + "", image: spec_img });
                }
              }
            }
          }
        }
        let params = {
          id: this.id,
          images: images,
        };
        if (sku_list.length > 0) {
          params.sku_list = sku_list;
        }
        otherPostRequest("/goods.goodsDesignTask/syncTaskResult", params, {
          sign: md5(
            JSON.stringify(params) + "332994CF-622B-44FB-82DD-C11B1FFD95B5"
          ),
        }).then((res) => {
          if (typeof res == "string") {
            this.statusMsg = "error";
          } else {
            this.statusMsg = "success";
          }
        });
      });
    },

    createEffectCanvas(images, suffix) {
      return new Promise((resolve, reject) => {
        const that = this;
        const canvas = this.$refs.effectCanvas;
        canvas.height = 1600;
        if (canvas) {
          const ctx = canvas.getContext("2d");
          const imglen = images.length;
          (function f(n) {
            if (n < imglen) {
              const image = new Image();
              if (images[n] == "") {
                return f(n + 1);
              }
              image.src = images[n];
              image.setAttribute("crossOrigin", "anonymous");
              image.onload = () => {
                ctx.drawImage(image, 0, 0, 1600, 1600);
                f(n + 1);
              };
            } else {
              setTimeout(() => {
                that.ossClass
                  .putb64ToOss({
                    content: canvas.toDataURL(`image/${suffix || "png"}`),
                    type: "effect",
                  })
                  .then((res) => {
                    resolve(res);
                  });
              }, 100);
            }
          })(0);
        }
      });
    },

    loadOldDesignDatas() {
      return new Promise((resolve, reject) => {
        fetch(this.design_url)
          .then((response) => response.json())
          .then((res) => {
            resolve(res.design);
          });
      });
    },

    createDesignDatas(value) {
      return new Promise(async (resolve, reject) => {
        const promises = [];
        for (let index in value) {
          let canvas = this.$refs.editor[index].canvas;
          let json = value[index];
          promises.push(this.loadFromJSON(canvas, json, index));
          await Promise.all(promises);
          resolve(true);
        }
      });
    },
    loadFromJSON(canvas, json, index) {
      return new Promise(async (resolve, reject) => {
        canvas.loadFromJSON(json, async () => {
          canvas.renderAll();
          let repeat = canvas
            .getObjects()
            .find((item) => item.type == "image" && item.extraData.repeat.show);
          if (repeat) {
            await this.$refs.editor[index].changeRepeat(
              repeat,
              repeat.extraData.repeat
            );
          }
          resolve(true);
        });
      });
    },
    loadSpecColor(res) {
      return new Promise((resolve, reject) => {
        let currentSpec = res.model.spec_value_list.find(
          (item) => item.spec_value_str == this.currentSpecName
        );
        if (currentSpec.bind_action == 1) {
          let colorKey = currentSpec.spec_value_str.split(",")[0];
          let color = res.model.spec_value[0]["spec_list"].find(
            (item) => item.value == colorKey
          );

          for (let part of currentSpec.bind_parts.split(",")) {
            let designFace = this.designFaceData.find(
              (item) => item.design_name == part
            );
            if (designFace) {
              designFace.bg_color = color.color;
            }
            for (let effectIndex in this.effectData) {
              this.$refs.effectRef[effectIndex].setModelColor({
                name: part,
                color: color.color,
              });
            }
          }
        }
        resolve(true);
      });
    },
    loadFont(value) {
      return new Promise((resolve, reject) => {
        const font = new FontFace(value.name, `url(${value.url})`);
        font
          .load()
          .then(function (loadedFont) {
            document.fonts.add(loadedFont);
            resolve(value.name);
            // 字体加载完成后执行的代码
          })
          .catch(function (error) {
            console.error("Font loading failed: ", error);
          });
      });
    },
    createDesignFaces(designFaceList) {
      return new Promise(async (resolve, reject) => {
        const faceData = [];
        const modelParams = {};
        for (const index in designFaceList) {
          const face = designFaceList[index];
          let cloneData = deepClone(face);
          if ([1, 3].indexOf(face.is_diy) > -1) {
            if (cloneData.more_spec == 1) {
              let sku_diy_data = JSON.parse(deepClone(cloneData.sku_diy_data));
              let currentData = sku_diy_data.find(
                (item) => item.name == this.currentSpecName
              );
              cloneData = { ...cloneData, ...currentData };
            }
            const {
              design_size,
              production_size,
              recommend_size,
              crop_size,
              dotted_size,
            } = cloneData;
            const designSize = design_size.split("|");
            cloneData.maxwidth = designSize[0];
            cloneData.maxheight = designSize[1];
            const productionSize = production_size.split("|");
            cloneData.productionWidth = productionSize[0];
            cloneData.productionHeight = productionSize[1];
            const dottedSize = dotted_size.split("|");
            cloneData.dottedWidth = dottedSize[0];
            cloneData.dottedHeight = dottedSize[1];
            cloneData.crop_size = crop_size.split("|");
            cloneData.printquality = "";
            cloneData.recommend_size = recommend_size
              ? recommend_size.split("x").map(Number)
              : ["", ""];
            cloneData.design_full = 0; //1未铺满2已铺满
            faceData.push(cloneData);
            const modelData = deepClone(face);
            if (modelData.normalmap) {
              const normalmap = JSON.parse(modelData.normalmap);
              modelData.normalMap = {
                img: normalmap.img,
                repeat: normalmap.repeat,
                scale: normalmap.scale,
              };
            } else {
              modelData.normalMap = null;
            }
            modelData.default_texture = modelData.texture;
            modelParams[face.design_name] = modelData;
          } else {
            if (cloneData.normalmap) {
              const normalmap = JSON.parse(cloneData.normalmap);
              cloneData.normalMap = {
                img: normalmap.img,
                repeat: normalmap.repeat,
                scale: normalmap.scale,
              };
            } else {
              cloneData.normalMap = null;
            }
            modelParams[face.design_name] = cloneData;
          }
        }
        this.designFaceData = faceData;
        this.modelParams = modelParams;
        resolve(true);
      });
    },

    createEffectDatas(effectRes) {
      return new Promise(async (resolve, reject) => {
        let effectArr = [];
        if (effectRes.length > 0) {
          for (const i in effectRes) {
            const item = deepClone(effectRes[i]);
            let images = {};
            let model_url = {};
            let imgLayer = {};
            if (item.more_spec == 1) {
              for (let skuData of JSON.parse(deepClone(item.sku_diy_data))) {
                images[skuData.name] = skuData.images;
                model_url[skuData.name] = skuData.model;
                imgLayer[skuData.name] = Number(skuData.img_layer);
              }
            } else {
              let imageTemp = deepClone(item.images)
                ? deepClone(item.images).split(",")
                : [];
              for (let spec of this.productInfo.spec_value_list) {
                images[spec.spec_value_str] = imageTemp;
                model_url[spec.spec_value_str] = JSON.parse(item.model_url);
                imgLayer[spec.spec_value_str] = Number(item.img_layer);
              }
            }
            item.images = images;
            item.model_url = model_url;
            item.imgLayer = imgLayer;
            item.currentImgs = images[this.currentSpecName];
            item.currentImgIndex = imgLayer[this.currentSpecName];
            item.preview_img = "";
            item.preview_loaded = false;
            item.model_json = "";
            effectArr.push(item);
          }
          this.effectData = effectArr;
        }
        resolve(true);
      });
    },
  },

  async mounted() {
    const toast = this.$message({
      message: "正在还原设计内容...",
      center: true,
      type: "success",
    });

    const workspaceElement = document.getElementById("workspace");
    if (workspaceElement) {
      this.workspaceWidth = workspaceElement.offsetWidth;
      this.workspaceHeight = workspaceElement.offsetHeight;
    }
    let params = { id: this.$route.query.id };
    otherGetRequest(`/goods.goodsDesignTask/getTaskDetail`, params, {
      sign: md5(
        JSON.stringify(params) + "332994CF-622B-44FB-82DD-C11B1FFD95B5"
      ),
    }).then(async (res) => {
      if (typeof res === "string") {
        this.statusMsg = "error";
        return;
      }
      this.timer = setInterval(async () => {
        this.second += 1;
        if (this.second >= 120) {
          clearInterval(this.timer);
          window.close();
        }
      }, 1000);
      this.id = params.id;
      this.design_url = res.design.design_url;
      this.currentSpecName = res.task.spec_value_str;
      this.fontList = res.task.fonts;
      if (this.fontList.length > 0) {
        const promises = [];
        for (let font of this.fontList) {
          promises.push(this.loadFont(font));
        }
        await Promise.all(promises);
      }
      this.productInfo = res.model;
      this.target_type = res.task.target_type;
      this.is_sku_list = res.task.is_sku_list;
      this.ossClass = new OSS(res.sts_token);
      await Promise.all([
        this.createDesignFaces(this.productInfo.design_parts),
        this.createEffectDatas(this.productInfo.design_effects),
      ]);
      let designDatas = await this.loadOldDesignDatas(this.design_url);
      this.editor = this.$refs.editor[0];
      this.fabricCanvas = this.$refs.editor[0].canvas;
      this.currentFace = this.designFaceData[0];
      this.faceName = this.designFaceData[0].design_name;
      const effectPromises = [];
      for (const i in this.effectData) {
        if (this.effectData[i].is_diy) {
          effectPromises.push(this.$refs.effectRef[i].init());
        }
      }
      await Promise.all(effectPromises);
      await this.restoreAttr();
      await this.createDesignDatas(designDatas);
      await this.loadSpecColor(res);
      setTimeout(async () => {
        const promises = [];
        for (let index in this.designFaceData) {
          this.faceIndex = index;
          const name = this.designFaceData[index].design_name;
          promises.push(this.refreshTexture(index, name));
        }
        await Promise.all(promises);
        toast.close();
        this.handleSave();
      }, 300);
    });
  },
};
</script>
