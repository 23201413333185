var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"design-container"},[_c('el-main',{staticClass:"design-content"},[(_vm.statusMsg)?_c('div',{attrs:{"id":"status"}},[_vm._v(_vm._s(_vm.statusMsg))]):_vm._e(),_c('div',{staticClass:"content--center",attrs:{"id":"workspace"}},[(_vm.designFaceData.length > 0)?_c('div',{staticClass:"editor-canvas"},[_c('div',{staticClass:"editor-canvas-bg",style:({
            width: _vm.canvasW * _vm.zoomScale + 'px',
            height: _vm.canvasH * _vm.zoomScale + 'px',
          })}),_vm._l((_vm.designFaceData),function(item,index){return _c('fabric-editor',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.faceIndex),expression:"index == faceIndex"}],key:index,ref:"editor",refInFor:true,attrs:{"index":index,"value":item,"width":_vm.canvasW,"height":_vm.canvasH,"left":_vm.designFaceShiftX,"top":_vm.designFaceShiftY},on:{"zoomChange":_vm.handleZoomChange,"confirm":function($event){return _vm.setCanvas(index)}}})})],2):_vm._e()]),_c('div',{staticClass:"content--right",staticStyle:{"position":"absolute","right":"0"}},[_c('div',{staticClass:"design-effect"},[_c('div',{staticClass:"effect-preview"},[_c('el-carousel',{attrs:{"height":600 + 'px',"arrow":"always","autoplay":false,"trigger":"click"}},_vm._l((_vm.effectData),function(item,index){return _c('el-carousel-item',{key:index},[_c('effect',{ref:"effectRef",refInFor:true,attrs:{"value":item,"imgLayer":_vm.currentSpecName && item.imgLayer?.[_vm.currentSpecName]
                    ? item.imgLayer?.[_vm.currentSpecName]
                    : 1,"modelUrl":_vm.currentSpecName && item.model_url?.[_vm.currentSpecName]
                    ? item.model_url?.[_vm.currentSpecName]
                    : [],"id":index,"type":2,"images":_vm.currentSpecName && item.images?.[_vm.currentSpecName]
                    ? item.images?.[_vm.currentSpecName]
                    : [],"modelParams":_vm.modelParams}})],1)}),1)],1)])]),_c('canvas',{ref:"effectCanvas",staticStyle:{"position":"absolute","left":"100%","z-index":"1"},attrs:{"id":"effectCanvas","width":"1600","height":"1600"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }